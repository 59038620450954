import React from "react";
import ReactLoading from "react-loading";
import "./Loading.css";

function Loading(props) {
  return (
    <div className="loading">
      <ReactLoading
        type="spinningBubbles"
        color="rgb(63,94,251)"
      />
      <div className="text-center mt-5">
        <h1>Loading ...</h1>
      </div>
    </div>
  );
}

export default Loading;
